var gData = {
    getDepartement: function(codeDep) {
        if(!codeDep){
            return null;
        }
        let d = this.departements.find( (d) => d.codeDep === codeDep);
        if(d){
            d.hasUpdatedTaxe = this.hasUpdatedTaxe(d.codeRegion);
        }
        return d;
    },
    getRegion: function(codeRegion){
        if(!codeRegion){
            return null;
        }
        let r = this.regions.find( (r) => r.codeRegion === codeRegion);
        if(r){
            r.hasUpdatedTaxe = this.hasUpdatedTaxe(r.codeRegion);
        }
        return r;
    },
    getRegionByDep: function(codeDep){
        const d = this.getDepartement(codeDep);
        if(d){
            return this.getRegion(d.codeRegion);
        }
        return null;
    },
    hasUpdatedTaxe: function(codeRegion, year = 2024){
        if(!codeRegion){
            return null;
        }
        const index = {
            2024: [11, 22 ,24, 28]
        };
        return index[year] && index[year].includes(codeRegion);
    },
    rejectDocumentMotifs: [
        {index: 1, value: "La signature du cotitulaire actuel de votre véhicule est manquante. Merci de faire signer votre carte grise par XXXXXXXXXX figurant dans le C.4.1 de votre carte grise."},
        {index: 2, value: "xxx étant locataire du véhicule (C.3 sur la carte grise), n'est pas en droit de vendre le véhicule. Il doit d'abord effectuer un changement de titulaire de carte grise pour figurer en C.1 avant de pouvoir vendre."},
        {index: 3, value: "La signature du cotitulaire actuel de votre véhicule est manquante. Merci de faire signer votre certificat de cession partie ancien propriétaire par XXXXXXXXXX figurant dans le C.4.1 de votre carte grise."},
        {index: 4, value: "La date et heure de cession sont manquantes sur votre certificat de cession. (La date et heure sont à saisir au milieu de la partie ancien propriétaire)."},
        {index: 5, value: "Le lieu et la date de signature sont manquants sur votre certificat de cession sur la partie ancien propriétaire. (Fait à ….., Le …..)"},
        {index: 6, value: "Le lieu et la date de signature sont manquants sur votre certificat de cession sur la partie nouveau propriétaire. (Fait à ….., Le …..)"},
        {index: 7, value: "Merci de confirmer l’orthographe exacte du nom et prénom de l’acquéreur de votre véhicule par mail à l'adresse support@reflexecartegrise.fr ou en appelant le 0176284359."},
        {index: 8, value: "Veuillez fournir le Certificat de cession d'un véhicule d'occasion (Formulaire 15776*02) dûment complété."},
        {index: 9, value: "Justificatifs de domicile acceptés : Facture d'eau, gaz, électricité, Internet, téléphone fixe ou portable, dernier avis d'imposition, attestation d'assurance logement, quittance de loyer éditée par une agence immatriculée au RCS."},
        {index: 10, value: "Merci de présenter un justificatif de domicile à votre nom et prénom. A défaut : Une attestation d'hébergement et la pièce d'identité de l’hébergeur par mail à l'adresse support@reflexecartegrise.fr en précisant l’immatriculation de votre véhicule."},
        {index: 11, value: "Bien que votre contrôle technique soit en cours de validité, afin de procéder à un changement de titulaire de carte grise un contrôle technique PÉRIODIQUE réalisé dans les 6 derniers mois est exigé."},
        {index: 12, value: "Un contrôle technique PÉRIODIQUE réalisé dans les 6 derniers mois est exigé dans le cadre d’un changement de titulaire de carte grise. Les contrôles techniques d’autres natures (volontaires, etc.) sont irrecevables."},
        {index: 13, value: "Merci de passer votre contre-visite prévue à la date du xx/xx/xxxx et de revenir vers nous avec le procès verbal de celle-ci afin que nous puissions donner suite à votre démarche."},
        {index: 14, value: "Ce contrôle technique n'est pas enregistré sur Système SIV. Contactez votre contrôleur pour l'enregistrer. A défaut et si vous êtes sûr de l'authenticité de votre contrôle actuel, nous pouvons déposer votre dossier à l'ANTS. Plus d'infos au 0176284359."},
        {index: 15, value: "La déclaration d'achat qui incombe au vendeur professionnel n'a pas été réalisée sur le système de l'État. Merci de vous rapprocher de votre vendeur afin qu'il fasse le nécessaire et revenir vers nous avec le récépissé de cette déclaration."},
        {index: 16, value: "A défaut de carte grise, veuillez fournir le certificat de vente publique avec lequel votre vendeur a acheté le véhicule aux enchères. Document prouvant que la carte grise n'a pas été remise lors de l'acquisition du véhicule par votre vendeur pro."},
        {index: 17, value: "Veuillez fournir la facture d'achat ou le courrier de fin de leasing (Courrier/Lettre électronique ou postal ACCOMPAGNANT votre certificat de cession, confirmant que vous pouvez procéder au changement de titulaire de la carte grise)."},
        {index: 18, value: "Veuillez fournir un justificatif d’identité sur lequel figure votre nom d’usage XXXX."},
        {index: 19, value: "Dans la mesure où votre permis définitif n’est pas encore disponible, merci de nous joindre une pièce d’identité en cours de validité."},
        {index: 20, value: "Dans la mesure où votre permis de conduire a été délivré dans un pays tiers, une pièce d’identité en cours de validité est exigée."},
        {index: 21, value: "Merci de fournir un BSR ou un permis de conduire. Pour les natifs après 1988, il est obligatoire de fournir l’un de ces documents pour conduire un véhicule de moins de 50cc."},
        {index: 22, value: "Merci de fournir un justificatif vous autorisant à conduire la catégorie de votre véhicule (Permis correspondant ou Petite carte permis moto)"},
    ],
    addressWayTypes: [
        {short: 'SANS', long: 'Sans'},
        {short: 'AERD', long: 'Aérodrome'},
        {short: 'AERG', long: 'Aérogare'},
        {short: 'ALL', long: 'Allée'},
        {short: 'A', long: 'Autoroute'},
        {short: 'AV', long: 'Avenue'},
        {short: 'BALC', long: 'Balcon'},
        {short: 'BRG', long: 'Barrage'},
        {short: 'BARR', long: 'Barrière'},
        {short: 'BASS', long: 'Bassin'},
        {short: 'BOIS', long: 'Bois'},
        {short: 'BD', long: 'Boulevard'},
        {short: 'BUTT', long: 'Butte'},
        {short: 'CARR', long: 'Carrefour'},
        {short: 'CAS', long: 'Caserne'},
        {short: 'CTRE', long: 'Centre'},
        {short: 'CCAL', long: 'Centre Commercial'},
        {short: 'CHAL', long: 'Chalet'},
        {short: 'CHP', long: 'Champ'},
        {short: 'CHAT', long: 'Château'},
        {short: 'CHAU', long: 'Chaussée'},
        {short: 'CHEM', long: 'Chemin'},
        {short: 'CD', long: 'Chemin Départemental'},
        {short: 'CITE', long: 'Cité'},
        {short: 'CLR', long: 'Clairiere'},
        {short: 'CLIM', long: 'Climat'},
        {short: 'CLOS', long: 'Clos'},
        {short: 'CONT', long: 'Contour'},
        {short: 'COR', long: 'Corniche'},
        {short: 'COTE', long: 'Cote'},
        {short: 'COTT', long: 'Cottage'},
        {short: 'CR', long: 'Cour'},
        {short: 'CRS', long: 'Cours'},
        {short: 'DOM', long: 'Domaine'},
        {short: 'DSC', long: 'Descente'},
        {short: 'ECA', long: 'Ecart'},
        {short: 'ECL', long: 'Écluse'},
        {short: 'ESC', long: 'Escalier'},
        {short: 'ESP', long: 'Esplanade'},
        {short: 'ETG', long: 'Étang'},
        {short: 'FBG', long: 'Faubourg'},
        {short: 'FERM', long: 'Ferme'},
        {short: 'FOND', long: 'Fond'},
        {short: 'FONT', long: 'Fontaine'},
        {short: 'FRT', long: 'Forêt'},
        {short: 'FORT', long: 'Fort'},
        {short: 'FOSS', long: 'Fosse'},
        {short: 'GAL', long: 'Galerie'},
        {short: 'GARE', long: 'Gare'},
        {short: 'GDAV', long: 'Grande Avenue'},
        {short: 'GDPL', long: 'Grande Place'},
        {short: 'GDR', long: 'Grande Rue'},
        {short: 'GRGE', long: 'Grange'},
        {short: 'HAM', long: 'Hameau'},
        {short: 'HLE', long: 'Halle'},
        {short: 'HIPP', long: 'Hippodrome'},
        {short: 'ILE', long: 'Ile'},
        {short: 'IMM', long: 'Immeuble'},
        {short: 'IMP', long: 'Impasse'},
        {short: 'JARD', long: 'Jardin'},
        {short: 'LDT', long: 'Lieu-Dit'},
        {short: 'LOT', long: 'Lotissement'},
        {short: 'MAIL', long: 'Mail'},
        {short: 'MAIS', long: 'Maison'},
        {short: 'MARC', long: 'Marche'},
        {short: 'MCH', long: 'Marché'},
        {short: 'MARE', long: 'Mare'},
        {short: 'METR', long: 'Métro'},
        {short: 'MONT', long: 'Mont'},
        {short: 'MNT', long: 'Montée'},
        {short: 'PAL', long: 'Palais'},
        {short: 'PARC', long: 'Parc'},
        {short: 'PKG', long: 'Parking'},
        {short: 'PARV', long: 'Parvis'},
        {short: 'PASS', long: 'Passage'},
        {short: 'PAV', long: 'Pavillon'},
        {short: 'PEL', long: 'Pelouse'},
        {short: 'PTAV', long: 'Petite Avenue'},
        {short: 'PTR', long: 'Petite Rue'},
        {short: 'PIEC', long: 'Pièce'},
        {short: 'PL', long: 'Place'},
        {short: 'PLAI', long: 'Plaine'},
        {short: 'PLT', long: 'Plateau'},
        {short: 'PK', long: 'Point Kilométrique'},
        {short: 'PNTE', long: 'Pointe'},
        {short: 'PONT', long: 'Pont'},
        {short: 'PORT', long: 'Port'},
        {short: 'PRTE', long: 'Porte'},
        {short: 'PRAI', long: 'Prairie'},
        {short: 'PRE', long: 'Pré'},
        {short: 'PROM', long: 'Promenade'},
        {short: 'QUAI', long: 'Quai'},
        {short: 'QUAR', long: 'Quartier'},
        {short: 'RPE', long: 'Rampe'},
        {short: 'RER', long: 'Rer'},
        {short: 'RES', long: 'Résidence'},
        {short: 'ROC', long: 'Rocade'},
        {short: 'RDPT', long: 'Rond-point'},
        {short: 'RTE', long: 'Route'},
        {short: 'RD', long: 'Route Départementale'},
        {short: 'RN', long: 'Route Nationale'},
        {short: 'RUE', long: 'Rue'},
        {short: 'RLE', long: 'Ruelle'},
        {short: 'SEN', long: 'Sente'},
        {short: 'SENT', long: 'Sentier'},
        {short: 'SQ', long: 'Square'},
        {short: 'ST', long: 'Station'},
        {short: 'TERR', long: 'Terrasse'},
        {short: 'TPL', long: 'Terre-Plein'},
        {short: 'TOUR', long: 'Tour'},
        {short: 'TRAV', long: 'Traverse'},
        {short: 'VAL', long: 'Val'},
        {short: 'VALL', long: 'Vallée'},
        {short: 'VEN', long: 'Venelle'},
        {short: 'VLA', long: 'Villa'},
        {short: 'VLGE', long: 'Village'},
        {short: 'VOIE', long: 'Voie'},
        {short: 'VC', long: 'Voie Communale'},
        {short: 'ZA', long: 'Zone Artisanale'},
        {short: 'ZAE', long: "Zone d'Activité"},
        {short: 'ZAC', long: "Zone d'Aménagement Concerté"},
        {short: 'ZAD', long: "Zone d'Aménagement Différé"},
        {short: 'ZI', long: 'Zone Industrielle'}
    ],
    statuses: [
        {id: 'all', name: 'Tous les statuts'},
        {id: 'archived', name: 'Archivé'},
        {id: 'awaiting-data', name: 'En attente des données'},
        {id: 'awaiting-documents-signature', name: 'En attente de signature des documents'},
        {id: 'awaiting-documents-upload', name: 'En attente de téléversement des documents'},
        {id: 'awaiting-payment', name: 'En attente de paiement'},
        {id: 'documents-signed', name: 'Documents signés'},
        {id: 'sent-to-tms', name: 'Démarche envoyée à TMS'},
        {id: 'tms-ready', name: 'Prêt pour l\'envoi à TMS'},
        {id: 'validated', name: 'Validée'}
    ],
    regions: [
        {codeRegion: 1, name: "Guadeloupe"},
        {codeRegion: 2, name: "Martinique"},
        {codeRegion: 3, name: "Guyane"},
        {codeRegion: 4, name: "La Réunion"},
        {codeRegion: 6, name: "Mayotte"},
        {codeRegion: 11, name: "Île-de-France"},
        {codeRegion: 21, name: "Grand Est"},
        {codeRegion: 22, name: "Hauts-de-France"},
        {codeRegion: 24, name: "Centre-Val de Loire"},
        {codeRegion: 27, name: "Bourgogne-Franche-Comté"},
        {codeRegion: 28, name: "Normandie"},
        {codeRegion: 52, name: "Pays de la Loire"},
        {codeRegion: 53, name: "Bretagne"},
        {codeRegion: 75, name: "Nouvelle-Aquitaine"},
        {codeRegion: 76, name: "Occitanie"},
        {codeRegion: 84, name: "Auvergne-Rhône-Alpes"},
        {codeRegion: 93, name: "Provence-Alpes-Côte d'Azur"},
        {codeRegion: 94, name: "Corse"}
    ],
    departements: [
        {codeDep: "01", name: "Ain", codeRegion: 84},
        {codeDep: "02", name: "Aisne", codeRegion: 22},
        {codeDep: "03", name: "Allier", codeRegion: 84},
        {codeDep: "04", name: "Alpes-de-Haute-Provence", codeRegion: 93},
        {codeDep: "05", name: "Hautes-Alpes", codeRegion: 93},
        {codeDep: "06", name: "Alpes-Maritimes", codeRegion: 93},
        {codeDep: "07", name: "Ardèche", codeRegion: 84},
        {codeDep: "08", name: "Ardennes", codeRegion: 21},
        {codeDep: "09", name: "Ariège", codeRegion: 76},
        {codeDep: "10", name: "Aube", codeRegion: 21},
        {codeDep: "11", name: "Aude", codeRegion: 76},
        {codeDep: "12", name: "Aveyron", codeRegion: 76},
        {codeDep: "13", name: "Bouches-du-Rhône", codeRegion: 93},
        {codeDep: "14", name: "Calvados", codeRegion: 28},
        {codeDep: "15", name: "Cantal", codeRegion: 84},
        {codeDep: "16", name: "Charente", codeRegion: 75},
        {codeDep: "17", name: "Charente-Maritime", codeRegion: 75},
        {codeDep: "18", name: "Cher", codeRegion: 24},
        {codeDep: "19", name: "Corrèze", codeRegion: 75},
        {codeDep: "2A", name: "Corse-du-Sud", codeRegion: 94},
        {codeDep: "2B", name: "Haute-Corse", codeRegion: 94},
        {codeDep: "21", name: "Côte-d'Or", codeRegion: 27},
        {codeDep: "22", name: "Côtes-d'Armor", codeRegion: 53},
        {codeDep: "23", name: "Creuse", codeRegion: 75},
        {codeDep: "24", name: "Dordogne", codeRegion: 75},
        {codeDep: "25", name: "Doubs", codeRegion: 27},
        {codeDep: "26", name: "Drôme", codeRegion: 84},
        {codeDep: "27", name: "Eure", codeRegion: 28},
        {codeDep: "28", name: "Eure-et-Loir", codeRegion: 24},
        {codeDep: "29", name: "Finistère", codeRegion: 53},
        {codeDep: "30", name: "Gard", codeRegion: 76},
        {codeDep: "31", name: "Haute-Garonne", codeRegion: 76},
        {codeDep: "32", name: "Gers", codeRegion: 76},
        {codeDep: "33", name: "Gironde", codeRegion: 75},
        {codeDep: "34", name: "Hérault", codeRegion: 76},
        {codeDep: "35", name: "Ille-et-Vilaine", codeRegion: 53},
        {codeDep: "36", name: "Indre", codeRegion: 24},
        {codeDep: "37", name: "Indre-et-Loire", codeRegion: 24},
        {codeDep: "38", name: "Isère", codeRegion: 84},
        {codeDep: "39", name: "Jura", codeRegion: 27},
        {codeDep: "40", name: "Landes", codeRegion: 75},
        {codeDep: "41", name: "Loir-et-Cher", codeRegion: 24},
        {codeDep: "42", name: "Loire", codeRegion: 84},
        {codeDep: "43", name: "Haute-Loire", codeRegion: 84},
        {codeDep: "44", name: "Loire-Atlantique", codeRegion: 52},
        {codeDep: "45", name: "Loiret", codeRegion: 24},
        {codeDep: "46", name: "Lot", codeRegion: 76},
        {codeDep: "47", name: "Lot-et-Garonne", codeRegion: 75},
        {codeDep: "48", name: "Lozère", codeRegion: 76},
        {codeDep: "49", name: "Maine-et-Loire", codeRegion: 52},
        {codeDep: "50", name: "Manche", codeRegion: 28},
        {codeDep: "51", name: "Marne", codeRegion: 21},
        {codeDep: "52", name: "Haute-Marne", codeRegion: 21},
        {codeDep: "53", name: "Mayenne", codeRegion: 52},
        {codeDep: "54", name: "Meurthe-et-Moselle", codeRegion: 21},
        {codeDep: "55", name: "Meuse", codeRegion: 21},
        {codeDep: "56", name: "Morbihan", codeRegion: 53},
        {codeDep: "57", name: "Moselle", codeRegion: 21},
        {codeDep: "58", name: "Nièvre", codeRegion: 27},
        {codeDep: "59", name: "Nord", codeRegion: 22},
        {codeDep: "60", name: "Oise", codeRegion: 22},
        {codeDep: "61", name: "Orne", codeRegion: 28},
        {codeDep: "62", name: "Pas-de-Calais", codeRegion: 22},
        {codeDep: "63", name: "Puy-de-Dôme", codeRegion: 84},
        {codeDep: "64", name: "Pyrénées-Atlantiques", codeRegion: 75},
        {codeDep: "65", name: "Hautes-Pyrénées", codeRegion: 76},
        {codeDep: "66", name: "Pyrénées-Orientales", codeRegion: 76},
        {codeDep: "67", name: "Bas-Rhin", codeRegion: 21},
        {codeDep: "68", name: "Haut-Rhin", codeRegion: 21},
        {codeDep: "69", name: "Rhône", codeRegion: 84},
        {codeDep: "70", name: "Haute-Saône", codeRegion: 27},
        {codeDep: "71", name: "Saône-et-Loire", codeRegion: 27},
        {codeDep: "72", name: "Sarthe", codeRegion: 52},
        {codeDep: "73", name: "Savoie", codeRegion: 84},
        {codeDep: "74", name: "Haute-Savoie", codeRegion: 84},
        {codeDep: "75", name: "Paris", codeRegion: 11},
        {codeDep: "76", name: "Seine-Maritime", codeRegion: 28},
        {codeDep: "77", name: "Seine-et-Marne", codeRegion: 11},
        {codeDep: "78", name: "Yvelines", codeRegion: 11},
        {codeDep: "79", name: "Deux-Sèvres", codeRegion: 75},
        {codeDep: "80", name: "Somme", codeRegion: 22},
        {codeDep: "81", name: "Tarn", codeRegion: 76},
        {codeDep: "82", name: "Tarn-et-Garonne", codeRegion: 76},
        {codeDep: "83", name: "Var", codeRegion: 93},
        {codeDep: "84", name: "Vaucluse", codeRegion: 93},
        {codeDep: "85", name: "Vendée", codeRegion: 52},
        {codeDep: "86", name: "Vienne", codeRegion: 75},
        {codeDep: "87", name: "Haute-Vienne", codeRegion: 75},
        {codeDep: "88", name: "Vosges", codeRegion: 21},
        {codeDep: "89", name: "Yonne", codeRegion: 27},
        {codeDep: "90", name: "Territoire de Belfort", codeRegion: 27},
        {codeDep: "91", name: "Essonne", codeRegion: 11},
        {codeDep: "92", name: "Hauts-de-Seine", codeRegion: 11},
        {codeDep: "93", name: "Seine-Saint-Denis", codeRegion: 11},
        {codeDep: "94", name: "Val-de-Marne", codeRegion: 11},
        {codeDep: "95", name: "Val-d'Oise", codeRegion: 11},
        {codeDep: "971", name: "Guadeloupe", codeRegion: 1},
        {codeDep: "972", name: "Martinique", codeRegion: 2},
        {codeDep: "973", name: "Guyane", codeRegion: 3},
        {codeDep: "974", name: "La Réunion", codeRegion: 4},
        {codeDep: "976", name: "Mayotte", codeRegion: 6}
    ]
};